<!--
 * @Author: sq
 * @Date: 2020-09-18 11:15:28
 * @LastEditTime: 2021-01-11 21:13:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\projectCollapse\projectCollapse.vue
-->

<template>
  <div class="project-box">
    <div class="project-collapse-box">
      <div class="project-collapse-wrap">
        <div class="project-collapse">
          <div class="project-collapse-menu" v-for="(item, index) in data" :key="index">
            <div class="project-collapse-menu-img">
              <h-img class="img" :imgUrl="item.img" :errorImg="item.defaultImg" :isUseBasePath="true"></h-img>
            </div>
            <div class="project-collapse-menu-data">
              <div class="project-collapse-menu-data-title">
                 <span  v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1" class="freeShipping">免运费</span>
                <span v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1" class="bright">璀璨</span>
                {{ item.productName }}
              </div>
              <div class="project-collapse-menu-data-desc project-collapse-menu-data-firstTop">
                品牌：{{ item.brandName }}
                <span class="color"></span>
                颜色：{{ item.color }}
              </div>
              <div class="project-collapse-menu-data-desc">体积：{{ item.loadVolume }}m³
                <span class="color"></span>
                批次：{{ item.batch }}
              </div>
            </div>
            <div class="project-collapse-menu-data project-collapse-menu-datal2">
              <div class="project-collapse-menu-data-title">
                <span class="project-collapse-menu-data-title">
                  ￥{{
                    formatPrice(item.billPriceShow)
                  }}
                </span>
                <span class="project-collapse-menu-data-title-zhekou">
                  ￥{{
                    formatPrice(item.price)
                  }}
                </span>
              </div>
              <div class="project-collapse-menu-data-desc project-collapse-menu-data-firstTop" v-if="item.deductShow">
                直扣：￥{{ formatPrice(item.deductShow) }}
              </div>
            </div>
            <div class="project-collapse-menu-data project-collapse-menu-datal2">
              <div class="project-collapse-menu-data-title">×{{ item.qty }}</div>
              <p class="can-no-buy" v-if="canBuy&&item.errorMessage">
                <img class="warn-img" :src="warningImg">{{ item.errorMessage }}</p>
              <!-- <div class="project-collapse-menu-data-desc">
              已下单 {{ item.finishOrder }} 台，剩余
              {{ item.amount - item.finishOrder }} 台
              </div>-->
            </div>
          </div>
        </div>

        <div class="project-control">
          <div class="project-control-title">
            <div class="project-control-title-top">组合内包含</div>
            <div class="project-control-title-btm">
              {{ chooseProductClass }} 个型号，{{ chooseProductNum }}
              件商品
            </div>
          </div>
          <div class="project-control-price left-div">
            <span class="project-control-price-title">组合单价：</span>
            <span style="color: #D9161C;font-size: 14px;">￥</span>
            <span class="project-control-price-desc">
<!--              {{ formatPrice(chooseSingleProductPrice) }}-->
                <span class="jg before"  style="font-size: 22px;font-weight: 500;color:#D9161C;">{{ chooseSingleProductPrice ? Number(chooseSingleProductPrice).toFixed(2).toString().split('.')[0] : '0' }}</span>
                <span class="jg after" style="font-size:12px;"><i class="dian">.</i>{{ chooseSingleProductPrice ? Number(chooseSingleProductPrice).toFixed(2).toString().split('.')[1] : '00'}}</span>
            </span>
          </div>
          <div class="project-control-num left-div" v-if="dataItem.statusName=='begin'">
            <div class="project-control-num-title">购买套数：</div>
            <div class="project-control-num-con">
              <slot name="changeCount"/>
<!--              <p class="can-no-buy" v-if="canBuy">{{ getErrMsg() }}</p>-->
            </div>
          </div>
        </div>
      </div>

      <!-- 底部结算 -->
      <div class="project-box-header" v-if="dataItem.statusName=='begin'">
        <div class="project-box-header-data">
          <div class="project-box-header-data-shop"></div>
          <div class="project-box-header-data-price">
            <div class="project-box-header-data-price-inset left-pad">
<!--              <span>已选择 {{ chooseNum > 0 ? chooseProductClass : '0' }} 种商品</span>-->
              <span style="margin-right: 12px">共 {{ chooseProductNum * chooseNum }} 件商品</span>
              <span @click="getInfo">
                体积合计{{ new Number(chooseProductVolume).toFixed(3) }}m³
                <!-- <i class="iconfont icon-tijiwenti-pc toast"  style="color:#00AAA6;margin-left:4px"></i> -->
                <volume-pop></volume-pop>
              </span>
            </div>
<!--            <div class="project-box-header-data-price-inset">-->
<!--              <span>合计：{{ new Number(chooseProductPrice).toFixed(2) }}元</span>-->
<!--            </div>-->
            <div class="project-box-header-data-price-inset">
              <p class="hj">合计：</p>
              <p class="priceNum">
                <span class="fh" style="line-height: 22px">￥</span>
                <span class="jg before"  style="font-size: 22px;font-weight: 500;color:#D9161C">{{ chooseProductPrice ? Number(chooseProductPrice).toFixed(2).toString().split('.')[0] : '0' }}</span>
                <span class="jg after" style="font-size:12px;line-height: 22px"><i class="dian">.</i>{{ chooseProductPrice ? Number(chooseProductPrice).toFixed(2).toString().split('.')[1] : '00'}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="project-box-header-submit" v-if="dataItem.statusName=='begin'">
          <h-btn @click="handleSubmit" width="188" height="54" :customType="!canBuy? '' : 3"  border-radius="0"
                 fontSize="16">立即下单
          </h-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import warningImg from '@/assets/icon/warning.png'
export default {
  data() {
    return {
      warningImg:warningImg,
      isSelectAll: false,
      chooseProductNum: 0,
      chooseSingleProductPrice: 0,
      chooseProductPrice: parseFloat('0.00'),
      chooseProductVolume: 0,
      chooseProductClass: 0,
      watchNum: 0
    };
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    dataItem: {
      type: [Array, Object],
      default: () => []
    },
    canBuy: {
      type: Boolean
    },
    chooseNum: {
      type: [Number, String]
    },
    dataIndex: {
      type: [Number, String]
    }
  },
  watch: {
    chooseNum: {
      immediate: true,
      handler(val) {
        this.watchNum = val;
      }
    },
    data: {
      immediate: true,
      handler() {
        this.chooseSingleProductPrice = 0;
        this.chooseProductClass = 0;
        this.chooseProductNum = 0;
        this.data.forEach(item => {
          this.chooseSingleProductPrice += item.billPriceShow * item.qty;
          this.chooseProductClass += 1;
          this.chooseProductNum += item.qty;
        });
      }
    }
  },
  computed: {
    canSelect: () => {
      let num = 0;
      this.data.productItem.forEach(element => {
        if (element.canSelect) {
          num += 1;
        }
      });
      return num;
    }
  },
  methods: {
    getErrMsg() {
      try {
        return this.data.map((it) => it.errorMessage).filter(Boolean).join(',')
      } catch (err){
        console.log(err)
        return ''
      }
    },
    formatPrice(price) {
      // if (Math.floor(price) === price) {
      //   return price
      // } else {
        return (Math.round(new Number(price) * 1000) / 1000).toFixed(2)
      // }
    },
    // 展开
    async isCollapseProOpen() {
      this.data.isCollapse = true;
    },

    handleSubmit() {
      if (this.canBuy) {
        return
      }
      this.$emit("handleSubmit", {
        chooseSingleProductPrice: this.chooseSingleProductPrice,
        chooseProductPrice: this.chooseProductPrice,
        chooseProductClass: this.chooseProductClass,
        chooseProductNum: this.chooseProductNum
      });
    },

    //当参数发生变化
    dataChange(num) {
      this.initProduct();
      // let chooseProductNum = 0,
      let chooseProductPrice = 0,
          // chooseSingleProductPrice = 0,
          // chooseProductClass = 0,
          chooseProductVolume = 0;
      this.data.forEach(item => {
        // chooseSingleProductPrice += item.billPrice * item.qty;
        chooseProductPrice += num * item.billPriceShow * item.qty;
        chooseProductVolume += num * item.loadVolume * item.qty;
        // chooseProductClass += num > 0 ? 1 : 0;
        // chooseProductNum += num * item.qty;
      });
      // this.chooseSingleProductPrice = chooseSingleProductPrice;
      // this.chooseProductNum = chooseProductNum;
      this.chooseProductPrice = chooseProductPrice.toFixed(2) || "0.00";
      // this.chooseProductClass = chooseProductClass;
      this.chooseProductVolume = chooseProductVolume.toFixed(3) || "0.00";
    },

    //初始化产品信息
    initProduct() {
      // this.chooseProductNum = 0;
      // this.chooseProductPrice = 0;
      // this.chooseProductClass = 0;
      this.chooseProductVolume = 0;
    },

    //获取物流提示
    getInfo() {
      this.$info({
        title: "体积提示",
        content: this.$store.state.user.getVolumnText
      });
    }
  }
};
</script>
<style lang="less" scoped>
@cardImgW: 196px;
@col26: #262626;
@col77: #777777;
@accountBtnW: 200px;
@f12: 12px;
@f14: 14px;
@f16: 16px;
@f18: 18px;
.project-box {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background: #fff;
  //   内容区域
  .project-collapse-wrap {
    display: flex;
    justify-content: space-between;

    .project-collapse {
      text-align: left;

      &-title {
        padding: 25px 24px 15px;
        font-size: @f16;
        color: @col26;
        font-weight: 600;
      }

      &-menu {
        padding: 22px 24px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        border-radius: 6px;
        width: 908px;
        height: 144px;
        background: #F7F7F7;
        &:last-child {
          border: none;
          margin-bottom: 0;
        }

        &-img {
          width: 100px;
          height: 100px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;

          .img {
            width: 80px;
            height: 80px;
          }
        }

        &-data {
          width: 248px;
          height: 100px;
          margin-left: 24px;
          text-align: left;

          &-title {
            font-size: @f14;
            color: @col26;
            font-weight: 500;
            .freeShipping {
              font-size: 12px;
              color: #fff;
              width: 42px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
              border-radius: 2px;
              margin-right: 0px;
              font-weight: 400;
            }
            .bright {
              font-size: 12px;
              color: #433538;
              width: 30px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
              border-radius: 2px;
              margin:0 4px 0 4px;
              font-weight: 400;
            }
            &-zhekou {
              text-decoration: line-through;
              color: rgb(183, 183, 183);
              font-weight: normal;
              margin-left: 15px;
            }
          }

          &-desc {
            font-size: @f12;
            color: #777;
            margin-top: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .color {
              margin-left: 16px;
            }
          }
          &-firstTop{
            margin-top: 15px;
          }
        }

        &-datal2 {
          width: 210px;
        }

        &-control {
          &-box {
            max-width: 120px;

            input {
              text-align: center;
            }
          }
        }
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        &-menu {
          box-shadow: 0px 0px 5px 2px rgba(238, 238, 238, 0.7);
        }
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .project-collapse-wrap {
      padding-bottom: 5px;
    }
  }

  .project-account {
    width: 1188px;
    background: #fff;
    margin: 0 auto;
    border-top: 16px solid #f7f7f7;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 18px 54px 18px 16px;
      width: calc(100% - @accountBtnW);

      &-right {
        &-price {
          font-size: 24px;
          font-weight: 600;
        }

        .color-red {
          color: #d9161c;
        }
      }
    }

    &-btn {
      width: @accountBtnW;
      height: 100%;

      &-a {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 18px 0;
        box-sizing: border-box;
        font-size: 16px;
      }
    }
  }

  .project-box {
    width: 1188px;
    margin: 16px auto 0;

    &-header {
      box-sizing: border-box;
      padding: 24px 0 0 0;
      background: #fff;
      display: flex;

      &-data {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        height: 64px;
        align-items: center;
        width: 100%;

        &-price {
          height: 100%;
          color: #777777;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;
          width: 450px;
          margin-right: 16px;
          padding: 10px 0;

          .left-pad {
            position: relative;
            left: 4px;
          }

          //&-inset {
          //  display: flex;
          //  flex-wrap: nowrap;
          //  line-height: 16px;
          //
          //  span {
          //    display: flex;
          //    align-items: center;
          //    margin-left: 18px;
          //
          //    .toast {
          //      width: 20px;
          //    }
          //  }
          //}
          &-inset{
            margin: 2px 0px 0px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            line-height: 16px;
            .hj{
              height: 16px;
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 16px;
            }
            .priceNum{
              display:flex;
              margin-top:5px;
              .fh {
                color: #D9161C;
                font-size: 14px;
              }
              .jg {
                color: #D9161C;
                font-size: 22px;
              }
            }
          }
        }
      }

      &-submit {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          //margin-left: 24px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
          width: 188px;
          height: 54px;
          background: linear-gradient(90deg, #FC3F85 0%, #FD3D23 100%)!important;
          border: none!important;
        }
      }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      /* IE10+ specific styles go here */
      &-header {
        padding-top: 20px;
      }
    }

    &-content {
      margin-top: 12px;
    }
  }
}

.project-control {
  width: 215px;
  margin-left: 16px;
  //box-shadow: 0px 1px 5px 2px rgba(238, 238, 238, 0.7);
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(360deg, #EEF9F9 0%, #FBFBFB 100%);
  border-radius: 8px;
  &-title {
    position: absolute;
    top: 0;
    left: 28px;
    width: 160px;
    height: 43px;
    background: #ecf8f8;
    color: #00aaa6;
    font-size: 12px;
    margin: 0 auto;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;

    &-top {
      text-align: center;
    }

    &-btm {
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &-price {
    white-space: nowrap;
    //text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 38px;
    text-align: center;
    width: 100%;
    //padding-left: 33px;
    &-title {
      color: #262626;
      font-size: 14px;
    }

    &-desc {
      color: #d9161c;
      font-size: 20px;
      font-weight: 500;
    }
  }

  &-num {
    &-title {
      color: #262626;
      font-size: 14px;
      text-align: left;
      margin-bottom: 16px;
    }

    &-con {
      width: 150px;
      .number-box {
        /deep/ .btn-number {
          background: #E4F8F7;
        }
        /deep/ .disbled {
          background: #eee;
        }

      }
    }
  }
}

.can-no-buy {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #FF8F3C;
  line-height: 16px;
  .warn-img{
    width: 14px;
    margin-right: 6px;
    vertical-align: text-bottom;
  }
}
.left-div{
  width: 100%;
  text-align: left;
  padding-left: 23px;
}
</style>

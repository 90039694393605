import { mapState } from "vuex";
import coverBuy from "./components/coverBuy";
import {  queryActivityList } from "@/utils/api";
export default {
  components: {
    coverBuy
  },
  data() {
    return {
      nolistImg: require('@/assets/order/noList.png'),
      showList: false,
      windowList: [],
      sortList: [
        {
          name: "发布时间",
          sortName:'modifyDate',
          sortStatus: 1 //0默认 1降 2升序
        },
        {
          name: "开始时间",
          sortName:'startDate',
          sortStatus: 0 //0默认 1降 2升序
        },
        {
          name: "结束时间",
          sortName:'endDate',
          sortStatus: 0 //0默认 1降 2升序
        }
      ],
      dataList: [],
      pageLoadFlag: false,
      pageSize: 10,
      total: 0,
      totalPages: 0,
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/package",
          name: "package",
          title: "套购专区"
        }
      ],
      coverList: [],
      collapse: false,
      filterFrom: {
        orgId: "", //组织id
        searchStr: "", //名称
        sortName:'modifyDate',
        sortType: "desc",
        status: "begin",
        // status: "",
        page: 1 //当前页
      }
    };
  },
  computed:{
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
     //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
     this.filterFrom = { ...this.filterFrom, formCode:this.filterConfigStr ? this.filterConfigStr: '' };

     //默认查询入参
    if (this.$route.query.condition) {
      this.filterFrom.condition = this.$route.query.condition;
    }
    //最后一项面包屑显示
    let breadcrumbLast = {
      title: this.$route.query.customName
        ?  this.$route.query.customName
        : "",
    };
    //最后一项面包屑
    if (breadcrumbLast.title) {
      this.$set(this.breadcrumbData, this.breadcrumbData.length, breadcrumbLast);
    }
    // //请求筛选公共接口 获取筛选数据
    // this.$store.dispatch("filtrate/systemConfigFormItem");
    // this.getEngineerOrderList();
    // this.queryOrgAndMatkl();
  },
  methods: {
    async getEngineerOrderList(type) {
      if (type == 'contract') {
        this.filterFrom.page += 1
      }
      this.pageLoadFlag = true;
      this.showList = false;
      try {
        const res = await queryActivityList(this.filterFrom);
        let {
          list,
          totalPages
        } = res.data;
        list.forEach(item => {
          item["chooseNum"] = 0;
          if(item.endDate){
            item.endDateShow = item.endDate.slice(0,10)
          }
          if(item.startDate){
            item.startDateShow = item.startDate.slice(0,10)
          }
        });
        if (type == 'contract') {
          this.coverList = this.coverList.concat(list)
        } else {
          this.coverList = list;
        }
        this.total = res.data.totalCount;
        this.totalPages = res.data.totalPages
        this.pageLoadFlag = false;
        this.showList = true;
      } catch (error) {
        this.pageLoadFlag = false;
        console.error(error);
      }
    },
    changeSearchVal(searchStr) {
      this.filterFrom = {
        ...this.filterFrom,
        searchStr,
        page: 1
      };
    },
    onSearch(searchStr) {
      this.preperList = [];
      this.filterFrom = {
        ...this.filterFrom,
        searchStr,
        page: 1
      };
      this.getEngineerOrderList();
    },

    handlePageChange() {
      this.getEngineerOrderList();
    },

    dataChange(index) {
      this.$refs.coverBuy[index].dataChange(this.coverList[index].chooseNum);
    },
    //输入
    handleSubmit(index, par) {
      const {
        chooseNum,
        id,
        productList
      } = this.coverList[index];
      if (chooseNum == 0) {
        // this.$message.warning("商品数量不可为0");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000055'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return false;
      }
      let params = {
        flag: 3,
        mainId: id,
        num: Number(chooseNum),
        ...par
      };
      const ids = []
      const counts = []
      productList.forEach(item => {
        ids.push(item.id)
        counts.push(item.qty * chooseNum)
      });
      params.ids = ids.join(',')
      params.counts = counts.join(',')
      this.$router.push({
        path: "/confirm/package",
        query: params
      });
    },
    onFilterChange(checkedObj) {
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0], page: 1};
      //查询列表
      this.getEngineerOrderList();
    },
    //筛选排序
    sortClick(data) {
      const {
        sortStatus
      } = data;
      this.filterFrom = {
        ...this.filterFrom,
        sortType: sortStatus == 0 ? "" : sortStatus == 2 ? "asc" : "desc",
        sortName:data.sortName,
        page: 1
      };
      this.getEngineerOrderList();
    },

    // 筛选数据重构
    getNewObj(obj) {
      let newArr = [],
        data = {
          id: "",
          name: "全部",
          isActive: true
        };
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key],
          isActive: false
        });
      }
      newArr.unshift(data);
      return newArr;
    }
  }
};